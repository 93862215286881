import React, { useRef } from 'react'
import { useEventContext } from '../../../../contexts/EventProvider'
import { ContentCopyRounded } from '../../../atoms/Icon'
import { NavCardActions, NavCardButton, NavWrapper, NavCardContent } from '../../../atoms/NavigationCard'
import CopyLink from '../../CopyLink'

const CopyLinkSelector = ({ toRecipient, ...rest }) => {
	const { event } = useEventContext()
	const copyRef = useRef()

	const handleClick = () => {
		if (copyRef?.current) copyRef?.current.click()
	}

	return (
		<NavWrapper onClick={handleClick} {...rest}>
			<NavCardContent
				title={toRecipient ? 'Private Link' : 'Shareable Link:'}
				description={
					<CopyLink
						as="span"
						event={event}
						forwardRef={copyRef}
						toRecipient={toRecipient}
						hideIcon
						flexDirection="row"
						justifyItems="left"
						pointerEvents="none"
					/>
				}
			/>
			<NavCardActions>
				<NavCardButton>
					<ContentCopyRounded />
				</NavCardButton>
			</NavCardActions>
		</NavWrapper>
	)
}

export default CopyLinkSelector
