import React from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

const ModalSendToContributors = ({ onClose, isOpen, contributorsNotified, participantCount, notify }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />

			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image w="120px" src={'/assets/images/graphics/email_illustration.svg'} alt="Email" />
						{contributorsNotified ? (
							<>
								<Heading size="lg" variant="hero">
									Already Notified
								</Heading>
								<Text>
									A message has already been sent to {participantCount} invite
									{participantCount > 1 && 's'}.
								</Text>
							</>
						) : (
							<>
								<Heading size="lg" variant="hero">
									Ready to Send?
								</Heading>
								<Text>A message will be sent to your invitation list.</Text>
								<Text>
									<small>It will not send to those who have been removed from the list.</small>
								</Text>
							</>
						)}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Not Now
						</Button>
						<Button
							onClick={() => {
								notify()
								onClose()
							}}>
							{contributorsNotified ? 'Resend' : 'Send'} Notification{participantCount > 1 && 's'}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalSendToContributors
