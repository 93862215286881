import { Box, Divider, Flex, useDisclosure } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from '../../../atoms/Icon'
import { NavCardActions, NavCardButton, NavWrapper, NavCardContent } from '../../../atoms/NavigationCard'
import InvitationMethods from '../../InvitationMethods'

const MoreSharingSelector = ({ isRecipient, toRecipient, ...rest }) => {
	const drawer = useDisclosure()

	return (
		<>
			<NavWrapper onClick={drawer.onToggle} {...rest}>
				<NavCardContent
					title={isRecipient ? 'More Options' : 'Social Options'}
					description="Quickly send or post your video"></NavCardContent>
				<NavCardActions>
					<NavCardButton>
						{drawer.isOpen ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
					</NavCardButton>
				</NavCardActions>
			</NavWrapper>
			{drawer.isOpen && (
				<Flex maxW={['100%', 'calc(100% - 1rem)']} position="relative" w="full" mt="1rem" mx={['-1rem', '0']}>
					<InvitationMethods
						theme={isRecipient ? 'dark' : 'white'}
						shareOption={toRecipient ? 'recipient' : 'public'}
						// disableButtonsControls={true}
						excludedDesktopOptions={['copymessage', 'copylink', 'vk', 'telegram', 'qzone']}
						excludedMobileOptions={['copymessage', 'copylink', 'vk', 'telegram', 'qzone']}
					/>
				</Flex>
			)}
		</>
	)
}

export default MoreSharingSelector
