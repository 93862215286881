/* **************************************
 *  LIBRARIES
 * ************************************ */

import { combineReducers } from 'redux'

/* **************************************
 *  PROJECT DEPENDENCIES
 * ************************************ */

import * as types from '../../constants'
import * as adminTypes from '../../../admin/constants'
import { TRACKS } from '../../media/constants'

/* **************************************
 *  INITIAL STATE
 * ************************************ */

const initialState = {
	isFetching: false,
	isOwner: false,
	data: {},
	temp: {
		// Upload overall stats
		isLoading: false,
		editMode: false,
		data: {},
		uploadsCompleted: [],
		uploads: [],
		processingMediaUuids: [],
		complete: false,
	},
}

function isOwner(state = initialState.isOwner, action = {}) {
	switch (action.type) {
		// From AuthedOrUnauthed component
		case types.SET_CURRENT_EVENT_OWNER:
			return Object.assign(action.data, state)

		// When ending impersonation, set owner to false (hmm)
		case adminTypes.UNMASK_SUCCESS:
			return Object.assign(false, state)
		default:
			return state
	}
}

function isFetching(state = initialState.isFetching, action = {}) {
	switch (action.type) {
		case types.LOAD_EVENT_REQUEST:
			return Object.assign(true, state)

		case types.LOAD_EVENT_FAILURE:
			return Object.assign(false, state)

		case types.LOAD_EVENT_SUCCESS:
			return Object.assign(false, state)

		case types.UPDATE_EVENT_SUCCESS:
			return Object.assign(false, state)

		default:
			return state
	}
}

function data(state = initialState.data, action = {}) {
	switch (action.type) {
		case types.UPDATE_EVENT_SUCCESS:
			return Object.assign({}, state, action.payload.data)

		case types.LOAD_EVENT_SUCCESS:
			return Object.assign({}, state, action.payload.data)

		case types.POST_MAIL_CONTRIBUTORS_SUCCESS:
			return Object.assign({}, state, action.payload.data)

		case TRACKS:
			POST_SUCCEEDED
			return Object.assign({}, state, {
				media: [...state.backgroundMusicIds, action.payload.id], // Add new media
			})

		case types.POST_EVENT_MEDIA_SUCCESS:
			return Object.assign({}, state, {
				media: [...state.media, action.payload.data], // Add new media
			})

		case types.PUT_EVENT_MEDIA_SUCCESS:
			return Object.assign({}, state, {
				...state,
				media: state.media.map((mediaItem) =>
					mediaItem.mediaUuid == action.payload.data.mediaUuid ? action.payload.data : mediaItem
				), // replace media item with new object
			})

		case types.REGENERATE_MEDIA_SUCCESS:
			return {
				...state,
				media: state.media.map((mediaItem) =>
					mediaItem.mediaUuid === action.payload.data.mediaUuid ? action.payload.data : mediaItem
				), // replace media item with new object
			}

		case types.CLEAR_CURRENT_EVENT_DATA:
			return {}

		// add newly added render jobs to currentEvent
		case adminTypes.ADD_ADMIN_RENDER_JOB_SUCCESS:
			return {
				...state,
				renderJobs: [action.payload.data, ...state.renderJobs],
			}

		// remove deleted render jobs from currentEvent
		case adminTypes.DELETE_ADMIN_RENDER_JOB_SUCCESS:
			return {
				...state,
				// filter out renderjob matching id deleted
				renderJobs: state.renderJobs.filter((job) => job.id != action.payload.id),
			}

		default:
			return state
	}
}

function temp(state = initialState.temp, action = []) {
	switch (action.type) {
		/* **************************************
			MEDIA
		************************************** */

		/// POSTING media to express server
		case types.POST_EVENT_MEDIA_REQUEST:
			return Object.assign({}, state, {
				isLoading: true,
				editMode: false,
				uploads: [...state.uploads, action.payload.data], // temp uploads object
			})

		case types.POST_EVENT_MEDIA_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				statusType: action.payload.statusType,
				statusMessage: action.payload.message,
				editMode: false,
				uploadsCompleted: [...state.uploadsCompleted, action.payload.data],
				uploads: state.uploads.filter((upload) => {
					return upload.mediaUuid !== action.payload.data.mediaUuid // return all the items not matching the action.id
				}),
			})

		// Media Uuid that are being processed on server
		case types.REGENERATE_MEDIA_REQUEST:
			return Object.assign({}, state, {
				processingMediaUuids: [...state.processingMediaUuids, action.payload.data.mediaUuid],
			})
		case types.REGENERATE_MEDIA_SUCCESS:
			return Object.assign({}, state, {
				processingMediaUuids: state.processingMediaUuids.filter((mediaUuid) => {
					return mediaUuid !== action.payload.data.mediaUuid // return all the items not matching the action.id
				}),
			})
		case types.REGENERATE_MEDIA_FAILURE:
			return Object.assign({}, state, {
				processingMediaUuids: state.processingMediaUuids.filter((mediaUuid) => {
					return mediaUuid !== action.payload.data.mediaUuid // return all the items not matching the action.id
				}),
			})

		case types.PUT_EVENT_MEDIA_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				statusType: action.payload.statusType,
				statusMessage: action.payload.message,
				editMode: state.editMode, // mantain object,
				data: {
					...state.data,
					// TODO this is a recurrring error; should state.media does not seem to exist???
					// media: state.data.media.map(mediaItem => mediaItem.mediaUuid === action.payload.data.mediaUuid ? action.payload.data : mediaItem), // Update singl Media
				},
				uploadsCompleted: state.uploadsCompleted.filter((upload) => {
					return upload.mediaUuid !== action.payload.data.mediaUuid // return all the items not matching the action.id
				}),
				uploads: [],
			})

		case types.POST_EVENT_MEDIA_FAILURE:
			return Object.assign({}, state, {
				isLoading: false,
				statusType: action.payload.statusType,
				statusMessage: action.payload.message,
				// data: {
				// 	...state.data,
				// 	// TODO this is a recurrring error; should state.media does not seem to exist???
				// 	// media: state.data.media.map(mediaItem => mediaItem.mediaUuid === action.payload.data.mediaUuid ? action.payload.data : mediaItem), // Update singl Media
				// },
			})

		case types.UPDATE_EVENT_MEDIA_REQUEST:
			return Object.assign({}, state, {
				isLoading: true,
			})

		case types.UPDATE_EVENT_MEDIA_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				statusType: action.payload.statusType,
				statusMessage: action.payload.message,
				editMode: false,
				data: {}, // clear temp media object
				uploadsCompleted: [], // clear temp uploads object
				uploads: [], // clear temp uploads object
			})

		case types.UPDATE_EVENT_MEDIA_FAILURE:
			return Object.assign({}, state, {
				isLoading: false,
				statusType: action.payload.statusType,
				statusMessage: action.payload.message,
			})

		case types.DELETE_MEDIA_SUCCESS:
			return Object.assign({}, state, {
				isLoading: false,
				percentage: 0,
				statusType: action.payload.statusType,
				statusMessage: action.payload.message,
				editMode: false,
				data: {}, // clear temp media object
				uploadsCompleted: [], // clear temp uploads object
				uploads: [], // clear temp uploads object
			})

		case types.SET_CURRENT_MEDIA_TO_EDIT:
			return Object.assign({}, state, {
				isLoading: false,
				editMode: true,
				data: action.payload.data.length <= 0 ? {} : action.payload.data, //Object.assign([], state.data, action.data)//[{...state.data,...action.data}]
			})

		case types.RELOAD_CURRENT_MEDIA_TO_EDIT:
			return Object.assign({}, state, {
				isLoading: false,
				editMode: true,
				data: Object.values(action.payload.data).length <= 0 ? {} : action.payload.data,
				// data: state.data.map(
				// 	(media) => {return media.mediaUuid == action.payload.data.mediaUuid ? action.payload.data : media;}
				// ), //[{...state.data,...action.data}] //Object.assign([], state.data, action.data)
			})

		default:
			return { ...state }
	}
}

const currentEvent = combineReducers({ isFetching, isOwner, data, temp })

export default currentEvent
