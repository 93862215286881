import React from 'react'
import { Box, LightMode, useDisclosure } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { sharePublishedPage } from '../../../../../api/app/behaviors/current-event'
import { useEventContext } from '../../../../contexts/EventProvider'
import ModalSendToContributors from '../../../organisms/ModalSendToContributors'

import { NavWrapper, NavCardContent, NavCardActions, NavCardButton } from '../../../atoms/NavigationCard'
import { SendRounded } from '../../../atoms/Icon'
import { useRSVPFiltered } from '../../../../hooks/useRSVPs'

const NotifyParticipants = ({ onClick, event, contributorCount, ...rest }) => {
	const { contributorsNotified } = event
	return (
		<NavWrapper onClick={onClick} {...rest}>
			<NavCardContent
				title={contributorsNotified ? `Notifications Sent` : `Notify Participants`}
				description={
					contributorsNotified
						? `General link sent to ${contributorCount} participant${contributorCount > 1 ? 's' : ''}!`
						: `Send the link all participants.`
				}
			/>
			<NavCardActions>
				<NavCardButton>
					<SendRounded marginRight="-4px" />
				</NavCardButton>
			</NavCardActions>
		</NavWrapper>
	)
}

const NotifyContributorsSelector = ({ isRecipient, ...rest }) => {
	const {
		event,
		event: { id, uuid, isPrivate, contributorsNotified },
	} = useEventContext()
	/** Retrieve unique active participants/RSVP of the current event */
	const { collection } = useRSVPFiltered()
	/** Manage modals state */
	const sendToModal = useDisclosure()

	const dispatch = useDispatch()

	/**
	 * Handler to share the published page
	 */
	const handleSharePublishedPage = () => {
		if (id) {
			dispatch(sharePublishedPage({ id: id, privateKey: event.privateKey }))
		}
	}

	return (
		<Box {...rest}>
			{collection.length > 0 && (
				<NotifyParticipants onClick={sendToModal.onOpen} contributorCount={collection.length} event={event} />
			)}
			{isRecipient ? (
				<LightMode>
					<ModalSendToContributors
						contributorsNotified={contributorsNotified}
						participantCount={collection.length}
						notify={handleSharePublishedPage}
						isOpen={sendToModal.isOpen}
						onClose={sendToModal.onClose}
					/>
				</LightMode>
			) : (
				<ModalSendToContributors
					contributorsNotified={contributorsNotified}
					participantCount={collection.length}
					notify={handleSharePublishedPage}
					isOpen={sendToModal.isOpen}
					onClose={sendToModal.onClose}
				/>
			)}
		</Box>
	)
}

export default NotifyContributorsSelector
