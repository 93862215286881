/* ******************************
 * LIBRARIES
 ***************************** */

import { RSAA } from 'redux-api-middleware'

/* ******************************
 * PROJECT DEPENDENCIES
 ***************************** */

import * as types from '../../constants'
import { addFlashMessage } from '../../behaviors/flash-messages'
import { retrieveURL } from '@vidday/utils'

/* ******************************
 * Select the media object to edit
 * from the current event
 ***************************** */

export function setCurrentEventIsOwner(data) {
	return {
		type: types.SET_CURRENT_EVENT_OWNER,
		data: data,
	}
}

export function setCurrentMediaToEdit(data) {
	return {
		type: types.SET_CURRENT_MEDIA_TO_EDIT,
		payload: { data: data },
	}
}

export function reloadCurrentMediaToEdit(data) {
	return {
		type: types.RELOAD_CURRENT_MEDIA_TO_EDIT,
		payload: { data: data },
	}
}

// Published Media Actions
export function sendPrivatePublishedPage(data) {
	return (dispatch, getState) => {
		dispatch({
			[RSAA]: {
				types: [
					types.POST_MAIL_RECIPIENT_PRIVATE_PAGE_REQUEST,
					{
						type: types.POST_MAIL_RECIPIENT_PRIVATE_PAGE_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', json.message))
								return json
							})
						},
					},
					types.POST_MAIL_RECIPIENT_PRIVATE_PAGE_FAILURE,
				],
				endpoint: `${retrieveURL()}/api/v2/events/${data}/deliver`,
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify({ id: data }),
			},
		})
	}
}

// Published Media Actions
export function sharePublishedPage(data, muteAlert) {
	return (dispatch, getState) => {
		dispatch({
			[RSAA]: {
				types: [
					types.POST_MAIL_CONTRIBUTORS_REQUEST,
					{
						type: types.POST_MAIL_CONTRIBUTORS_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								console.log('MAILER RESPONSE', json)
								if (!muteAlert) dispatch(addFlashMessage('success', 'Email sent to participant list.'))
								return json
							})
						},
					},
					types.POST_MAIL_CONTRIBUTORS_FAILURE,
				],
				endpoint: retrieveURL() + '/api/mail/contributors',
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(data),
			},
		})
	}
}

// Broadcast Message to contributor list
export function broadcastMessage(data) {
	return (dispatch, getState) => {
		dispatch({
			[RSAA]: {
				types: [
					types.POST_MAIL_BROADCAST_MESSAGE_REQUEST,
					{
						type: types.POST_MAIL_BROADCAST_MESSAGE_SUCCESS,
						payload: (action, state, res) => {
							return res.json().then((json) => {
								dispatch(addFlashMessage('success', 'Message sent to list'))
								return json
							})
						},
					},
					types.POST_MAIL_BROADCAST_MESSAGE_FAILURE,
				],
				endpoint: retrieveURL() + '/api/mail/broadcast',
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify(data),
			},
		})
	}
}

export function clearCurrentEvent() {
	return {
		type: types.CLEAR_CURRENT_EVENT_DATA,
	}
}
