/**
 * Method that allow sorting media in a given sorting order
 * @param {array} items
 * @param {array} sortingOrder
 */
export const sortMedia = (items, sortingOrder = null) => {
	// Host reference to media that were contained in the 'mediaSortingOrder' from the event
	var sortedMedia = items.filter((n) => {
		return n.id !== null && n.id !== undefined
	})
	// Make sure we have media
	if (items && items.length > 0 && sortingOrder) {
		// Add medias to their respective array and appends the unfound items at the end
		// uniq = [...new Set(array)]
		// Speed gain, make mapping of id to index ONCE, ahead of time
		let indexMap = {}
		sortingOrder.forEach((id, i) => {
			indexMap[id] = i
		})
		sortedMedia.sort((item1, item2) => {
			let index1 = indexMap[item1.id] // O(1) instead of O(n)
			let index2 = indexMap[item2.id] // O(1) instead of O(n)
			return (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity)
		})
		return sortedMedia
	} else return []
}

/**
 * Method that allow sorting media in a given sorting order
 * @param {object} e DOM event
 * @param {string} text text to copy
 * @param {boolean} showMsg display a "copied" message within parent element
 */
export const copyText = (e, text, showMsg) => {
	var currentTarget

	e?.preventDefault()

	var textArea = document.createElement('textarea')
	textArea.style.position = 'fixed'
	textArea.style.top = window.scrollY - 80 + 'px'
	textArea.style.left = 0
	textArea.style.width = '1px'
	textArea.style.height = '1px'
	textArea.style.padding = 0
	textArea.style.border = 'none'
	textArea.style.outline = 'none'
	textArea.style.boxShadow = 'none'
	textArea.style.background = 'transparent'
	textArea.value = decodeURIComponent(text)
	document.body.appendChild(textArea)

	if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
		var el = textArea
		var editable = el.contentEditable
		var readOnly = el.readOnly
		el.contentEditable = true
		el.suppressContentEditableWarning = true
		el.readOnly = true
		var range = document.createRange()
		range.selectNodeContents(el)
		var sel = window.getSelection()
		sel.removeAllRanges()
		sel.addRange(range)
		el.setSelectionRange(0, 999999)
		el.contentEditable = editable
		el.readOnly = readOnly
	} else {
		textArea.select()
	}

	var thisTarget = (currentTarget = e?.currentTarget) == null ? void 0 : currentTarget.parentNode
	var thisInnerText = e?.currentTarget
	var thisInnerTextOrig = thisInnerText == null ? void 0 : thisInnerText.innerHTML

	try {
		navigator.clipboard.writeText(decodeURIComponent(text)).then(
			function () {
				thisTarget == null ? void 0 : thisTarget?.classList.add('copied')

				if (showMsg) {
					thisInnerText.innerHTML = '<p style="font-size:12px;font-weight:600">Copied to Clipboard</p>'
				}

				setTimeout(function () {
					thisTarget?.classList.remove('copied')

					if (showMsg) {
						thisInnerText.innerHTML = thisInnerTextOrig
					}
				}, 3000)
			},
			function () {
				console.error('Copy failed')
				return false
			}
		)
		return true
	} catch (err) {
		console.error(err, 'Copy failed')
		return false
	}
}

/**
 * Format sms to E.164 format: e.g. "+11234567890"
 * @param {string} smsNumber phone number
 * @param {string} countryCode defaults to 1
 * @returns {string} formated number
 */
export const convertToE164 = (smsNumber, countryCode = '1') => {
	// Remove non-digit characters and trim spaces
	const cleanNumber = smsNumber
		.replace(/\D/g, '')
		.replace('+', '')
		.replace('-', '')
		.replace('(', '')
		.replace(')', '')
		.trim()

	// Extract the local area code
	const localAreaCode = cleanNumber.length > 10 ? cleanNumber.slice(1, 4) : null

	// Check if the clean number starts with the local area code
	if (localAreaCode && cleanNumber.startsWith(localAreaCode)) {
		// Add the country code but remove the local area code
		const withoutLocalAreaCode = cleanNumber.slice(localAreaCode.length)
		return `+${countryCode}${withoutLocalAreaCode}`
	}

	// Check if the clean number starts with the country code
	if (cleanNumber.startsWith(countryCode)) {
		// Already in E.164 format, no need to modify
		return `+${cleanNumber}`
	}

	// Concatenate the country code to the clean number
	const e164Number = `+${countryCode}${cleanNumber}`

	return e164Number
}
