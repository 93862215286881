import { useState, useEffect } from 'react'
import { useMediaQuery } from '@chakra-ui/react'

const useMultipleImages = (mobile, desktop) => {
	const [isIpad] = useMediaQuery('(min-width: 48em)')
	const [path, setPath] = useState()

	useEffect(() => {
		if (isIpad) {
			setPath(desktop)
		} else {
			if (path != mobile) {
				setPath(mobile)
			}
		}
	}, [isIpad])

	return path
}

export default useMultipleImages
