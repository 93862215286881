import React from 'react'
import { Box, DarkMode, Divider, Flex, Heading, Image } from '@chakra-ui/react'
import { useEventContext } from '../../../contexts/EventProvider'
import Card from '../../atoms/Card'
import useMultipleImages from '../../../hooks/useMultipleImages'
import NotifyContributorsSelector from './selectors/NotifyContributorsSelector'
import CopyLinkSelector from './selectors/CopyLinkSelector'
import MoreSharingSelector from './selectors/MoreSharingSelector'
import { useRSVPFiltered } from '../../../hooks/useRSVPs'
import { useEventRecipients } from '../../../hooks/useRecipients'

const SharingOptionsCard = ({ toRecipient, variant, role, hideHeader, ...rest }) => {
	const recipients = useEventRecipients()
	/** Retrieve unique active participants/RSVP of the current event */
	const { collection } = useRSVPFiltered()

	const {
		event: { isPrivate },
		isOwner,
	} = useEventContext()
	const isRecipient = role == 'recipient'

	const pathLeft = useMultipleImages(
		'/assets/images/graphics/sharing-header/vidday-share-left-mobile.svg',
		'/assets/images/graphics/sharing-header/vidday-share-left-desktop.svg'
	)
	const pathRight = useMultipleImages(
		'/assets/images/graphics/sharing-header/vidday-share-right-mobile.svg',
		'/assets/images/graphics/sharing-header/vidday-share-right-desktop.svg'
	)

	const children = (
		<Box w="full">
			{!toRecipient && collection.length > 0 && (
				<>
					<Box p="1rem 0 1rem 1rem">
						<NotifyContributorsSelector isRecipient={isRecipient} variant={variant} />
					</Box>
					<Divider />
				</>
			)}
			<Box p="1rem 0 1rem 1rem">
				<CopyLinkSelector toRecipient={toRecipient} variant={variant} />
			</Box>

			<Divider />

			<Box p="1rem 0 1rem 1rem">
				<MoreSharingSelector isRecipient={isRecipient} toRecipient={toRecipient} variant={variant} />
			</Box>
		</Box>
	)

	return (
		<Card
			flexDirection="column"
			alignItems="center"
			overflow="visible"
			sx={{
				filter: isPrivate && !toRecipient ? 'grayscale(100) opacity(0.4)' : '',
				pointerEvents: isPrivate && !toRecipient ? 'none' : 'auto',
			}}
			{...rest}>
			{!hideHeader && (
				<Flex
					bg="blue.500"
					h="7rem"
					w="full"
					borderRadius={'var(--vidday-radii-xl) var(--vidday-radii-xl) 0 0'}
					position="relative"
					justifyContent={'space-between'}
					alignItems={'center'}>
					{pathLeft && <Image src={pathLeft} alt="sharing header" />}
					{pathRight && <Image src={pathRight} alt="sharing header" />}
					<Heading size="lg" color="white" w="full" textAlign={'center'} position="absolute" noOfLines={1}>
						{toRecipient ? `Share with ${recipients.firstname}` : `Ways to Share`}
					</Heading>
				</Flex>
			)}
			{isRecipient ? <DarkMode>{children}</DarkMode> : <>{children}</>}
		</Card>
	)
}

export default SharingOptionsCard
